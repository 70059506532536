import React from 'react'
import styled from 'styled-components'

const StyledLive = styled.svg`
  fill: none;
  stroke: ${props => props.theme.iconColor};
  width: 15px;
  height: 15px;
  padding-right: 5px;
`

const Live = () => {
  return (
    <StyledLive
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
      <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
    </StyledLive>
  )
}

export default Live
