export const blue = {
  100: '#C4DDEE',
  200: '#3678A4',
  300: '#2375B0',
  400: '#304859',
  500: '#11202C',
}

export const dark = {
  100: '#3F3F3F',
  200: '#323232',
  300: '#121212',
  400: '#0C0C0C',
}

export const green = {
  100: '#c9ffc9',
  200: '#10E036',
  300: '#017817',
  400: '#237532',
  500: '#144F1F',
  600: '#09130A',
}

export const neutral = {
  100: '#FFFFFF',
  200: '#F4F5F7',
  300: '#E1E1E1',
  400: '#737581',
  600: '#000000',
}

export const pink = {
  100: '#D6B6B9',
  200: '#D1B2B5',
  300: '#B79093',
  400: '#E5969E',
  500: '#DE7882',
}

export const red = {
  100: '#734B60',
  200: '#381A2A',
  300: '#3D0121',
  400: '#1C0000',
  500: '#0A0809',
}
